import { debounce } from '../../resources/js/helpers/Utils';

class Subnav {
	/**
	 * Create and initialise objects of this class
	 * @param {object} block
	 */
	constructor(block) {
		this.block = block;
		this.blockContainer = this.block.querySelector('.block-container');
		this.init();
	}

	init() {
		if ( this.block.classList.contains('type--menu') || this.block.classList.contains('type--pages')) {
			this.linksMenu = this.block.querySelector('.block-subnav__menu');
			this.links = this.block.querySelectorAll('.block-subnav__menu li:not(.more)');
			this.moreLink = this.block.querySelector('.block-subnav__menu li.more');
			this.moreContainer = this.block.querySelector('.block-subnav__menu li.more ul');

			// on load
			this.checkLinks();

			/**
			 * Debounce the resize event
			 */
			const resizeHandler = debounce( () => {
				this.checkLinks();
			}, 250);
			window.addEventListener('resize', resizeHandler);
		}
	}

	checkLinks() {
		const containerWidth = this.blockContainer.clientWidth;
		let totalWidth = 100; // ~100 offset each end and more link

		this.moreLink.classList.add('hide');
		this.moreContainerLinks = this.moreContainer.querySelectorAll('li');
		this.moreContainerLinks.forEach( link => this.linksMenu.appendChild(link) );
		this.linksMenu.appendChild(this.moreLink);

		this.links.forEach( link => {
			const linkWidth = link.offsetWidth;

			totalWidth += (linkWidth + 40); // ~40 is the padding

			if ( totalWidth > containerWidth ) {
				this.moreLink.classList.remove('hide');
				this.moreContainer.appendChild(link);
			}
		});
	}
}

document.addEventListener('DOMContentLoaded', () => {
	document.querySelectorAll('.block-subnav').forEach( block => new Subnav(block) );
});
